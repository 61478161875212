<template>
  <Dropdown label="Versions" :ui="{ label: 'text-brand', items: 'lg:left-1/2 lg:-translate-x-1/2' }">
    <DropdownSection>
      <DropdownItem :button="{ prependIcon: 'pencil' }" @click="renamePersonalisation">Rename This Version</DropdownItem>
      <DropdownItem :button="{ prependIcon: 'plus' }" @click="$modal.open(NewPersonalisationsModal, { sharedCollectionHashid })">New Version</DropdownItem>
      <DropdownItem :button="{ prependIcon: 'question', prependIconPrefix: 'fas' }" @click="$modal.open(LearnMoreModal)">Learn More</DropdownItem>
    </DropdownSection>
    <DropdownSection>
      <DropdownItem
        v-for="personalisation in personalisations"
        :key="personalisation.landing_page_url"
        :button="personalisation.hashid === $route.meta.personalisationHashid ? { appendIcon: 'check' } : undefined"
        @click="changePersonalisation(personalisation)"
        >{{ personalisation.name }}</DropdownItem
      >
    </DropdownSection>
  </Dropdown>
</template>

<script setup lang="ts">
import { parseURL } from "ufo";

const props = defineProps<{
  sharedCollectionHashid: string;
  personalisations: LandingPage["personalisations"];
}>();

const NewPersonalisationsModal = resolveComponent("LazyNewPersonalisationsModal");
const RenamePersonalisationsModal = resolveComponent("LazyRenamePersonalisationsModal");
const LearnMoreModal = resolveComponent("LazyPersonalisationsLearnMoreModal");

const renamePersonalisation = async () => {
  const { getPersonalisation, updatePersonalisation } = useLandingPage();
  const { openModal } = useModal();

  const updatedPersonalisation = await openModal<LaravelResponse<CollectionPersonalisation>>(RenamePersonalisationsModal, {
    sharedCollectionHashid: props.sharedCollectionHashid,
    personalisation: getPersonalisation(useRoute().meta.personalisationHashid).value,
  });

  if (updatedPersonalisation?.data?.id) {
    updatePersonalisation(updatedPersonalisation.data);
  }
};

const changePersonalisation = async (personalisation: LandingPage["personalisations"][number]) => {
  const pathname = parseURL(personalisation.landing_page_url).pathname;
  await navigateTo(pathname);
};
</script>
